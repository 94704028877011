<template>
  <div>
    <div class="backgroundOV"></div>
    <div>
      <v-card outlined>
        <v-card-title class="titleLarge"
          >Nicht zählendes Rechnen – Übergang Diagnose</v-card-title
        >
      </v-card>
      <br />
      <br />
      <v-card outlined>
        <v-card-title class="titleSmaller"
          >Welche Facetten des Hintergrundwissens konnten beim nicht-zählenden
          Rechnen ausgemacht werden?</v-card-title
        >
        <v-card-text
          >Das Hintergrundwissen im Bereich nicht-zählendes Rechnen lässt sich
          aufgliedern in die drei Facetten:
          <ul>
            <li>Teil-Ganzes-Verständnis</li>
            <li>Erweiterung von Zählkompetenzen</li>
            <li>Aufbau flexibler Rechenstrategien</li>
          </ul>
          Das Wissen um diese drei Teilbereiche ist vor allem für die
          Diagnose und Förderung der Kinder wichtig, um zum einen
          diagnostizieren zu können, wo genau beispielsweise Probleme auftreten
          und daran anschließend passgenaue Förderung anzustreben.
        </v-card-text>
      </v-card>
      <br />
      <br />
      <v-card outlined>
        <v-card-title class="titleSmaller"
          >Was erwartet Sie im Bereich der Diagnose und Förderung zum
          nicht-zählenden Rechnen?</v-card-title
        >
        <v-card-text
          >Anhand zweier Aufgaben betrachten Sie gleich Lösungen von Kindern, um
          Förderaufgaben für ein Kind diagnosegeleitet auszuwählen.</v-card-text
        >
      </v-card>
    </div>
    <AppBottomNavNZR
      next="/nicht-zaehlendes-rechnen/diagnose-und-foerderung"
      back="/nicht-zaehlendes-rechnen/hintergrundwissen/aufbau-flexibler-rechenstrategien"
    />
  </div>
</template>

<script>
import AppBottomNavNZR from "@/common/AppBottomNavNZR";
export default {
  components: {
    AppBottomNavNZR,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
